











































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
//引入组件
import VueUeditorWrap from 'vue-ueditor-wrap';
import { BonusService } from '@/services/bonus-service';
import productMapOpenDialog from '@/components/wechatEditor/productMapOpenDialog.vue';


@Component({
  components: {
    VueUeditorWrap,
    productMapOpenDialog,
  }
})
export default class addProductMap extends Vue {
  public myConfig = {
    // 编辑器不自动被内容撑高
    autoHeightEnabled: false,
    // 初始容器高度
    initialFrameHeight: 500,
    // 初始容器宽度
    initialFrameWidth: '100%',
    // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
    serverUrl: '/api/application/ueditor/config',
    // serverUrl: '/api/application/img/upload/imgUpload',
    // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
    UEDITOR_HOME_URL: '/ueditor/',
    toolbars: [[
      'fullscreen', 'source', '|', 'undo', 'redo', 'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript',
      'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', 'forecolor',
      'backcolor', 'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc',
      'fontfamily', 'fontsize', 'simpleupload', 'insertimage', 'emotion', /*'scrawl',*/
      /*'music',*/ 'insertvideo', 'insertaudio', 'attachment', 'map',
      'rowspacingtop', 'rowspacingbottom', 'lineheight', 'link', 'unlink'
    ]],
    'fontfamily': [
      { label: '', name: 'songti', val: '宋体,SimSun' },
      { label: '仿宋', name: 'fangsong', val: '仿宋,FangSong' },
      { label: '仿宋_GB2312', name: 'fangsong', val: '仿宋_GB2312,FangSong' },
      { label: '', name: 'kaiti', val: '楷体,楷体_GB2312, SimKai' },
      { label: '', name: 'yahei', val: '微软雅黑,Microsoft YaHei' },
      { label: '', name: 'heiti', val: '黑体, SimHei' },
      { label: '', name: 'lishu', val: '隶书, SimLi' },
      { label: '', name: 'andaleMono', val: 'andale mono' },
      { label: '', name: 'arial', val: 'arial, helvetica,sans-serif' },
      { label: '', name: 'arialBlack', val: 'arial black,avant garde' },
      { label: '', name: 'comicSansMs', val: 'comic sans ms' },
      { label: '', name: 'impact', val: 'impact,chicago' },
      { label: '', name: 'timesNewRoman', val: 'times new roman' }
    ]
  };
  public labelPosition: String = 'top';
  public type: any = ''; //判断是修改还是添加
  public readonly: boolean = true;
  public loading: boolean = false;
  public spicurlAlt: String = '';
  public form: any = {
    showType: '',
    status: '1',
    name: '',
    icon: '',
    franchise: '',
  };
  public rules: any = {
    showType: [
      { required: true, message: '请选择类型', trigger: ['blur', 'change'] }],
    status: [
      { required: true, message: '请选择状态', trigger: ['blur', 'change'] }
    ],
    name: [
      { required: true, message: '请输入名称', trigger: 'blur' }
    ],
    franchise:[
      { required: true, message: '请输入 franchise', trigger: 'blur' }
    ],
    icon:[
      { required: true, message: '请上传 Icon'}
    ]
  };
  @Inject(BonusService) private bonusService!: BonusService;

  public async created(): Promise<void> {
    this.type = localStorage.getItem('awardManagement') || '';
    this.type != '-1'  && await this.getDetail();
  }
  //获取详情数据
  public async getDetail() {
    const res = await this.bonusService.awardsDetail({ name: this.type });
    this.form = res;
    this.spicurlAlt = res.icon;
  }
  //清除
  public clear() {
    this.form.icon = null;
    this.spicurlAlt = '';
  }
  public customPhotoUpload(file: any) {
    const formData = new FormData();
    formData.append('file', file.file);
    const loading = this.$loading({
      text: `上传中...`,
    });

    this.bonusService.posterUpload(formData).then((res) => {
      if (res) {
        this.form.icon = res;
        (this.$refs.iconFormItem as any).validate();
        return
      }
      this.$message({
        type: 'error',
        message: '上传失败'
      })
    }).catch((err) => {
      this.$message({
        type: 'error',
        message: '上传失败'
      })
    }).finally(() => {
      loading.close();
    });
  }
  //上传Icon
  public customUploadForPoster(file: any) {
    this.customPhotoUpload(file)
  }
  //调起图片弹框
  public addPicture(types: any) {
    (this.$refs.childDialog as productMapOpenDialog).upImage();
  }
  //得到icon的地址
  public getChild(val: any): void {
    this.$nextTick(() => {
      this.form.icon =  this.spicurlAlt =  val.src;
    });
  }
  //保存
  public async submitForm() {
      const addRef = (this.$refs.form as any)
      addRef.validate( async (valid: any) => {
          if (valid) {
            if(this.type == '-1'){
              const res = await this.bonusService.awardsCreate(this.form);
              if (res) {
                this.$message({
                  message: '添加成功',
                  type: 'success'
                });
                this.$router.push('/awardManagement')
              }
            }else{
              const res = await this.bonusService.awardsUpdate(this.form);
              if (res) {
                this.$message({
                  message: '修改成功',
                  type: 'success'
                });
                this.$router.push('/awardManagement')
              }
            }
          } else {
            console.log('error submit!!');
            return false;
          }
      });
  }
  //返回
  public async handleGoBack(): Promise<void> {
    this.$confirm('当前信息将会丢失，确认是否取消?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$message({
        type: 'success',
        message: '已返回'
      });
      this.$router.go(-1);
    }).catch(() => {
      this.$message({
        type: 'success',
        message: '已取消'
      });
    });
  }
}
